import React from 'react';

const ModalMenuPrimary = () => {
    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={"nav-item" + (window.location.pathname === '/' ? " current-nav-item" : "")}>
                    <a title="Home" href={process.env.PUBLIC_URL + "/"}>Acasa</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/servicii' ? " current-nav-item" : "")}>
                    <a title="Services" href={process.env.PUBLIC_URL + "/servicii"}>Servicii</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/about' ? " current-nav-item" : "")}>
                    <a title="About us" href={process.env.PUBLIC_URL + "/despre-noi"}>Despre noi</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/contacts' ? " current-nav-item" : "")}>
                    <a title="Contacts" href={process.env.PUBLIC_URL + "/contacts"}>Contact</a>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuPrimary;
