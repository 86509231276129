export const priceListItems = [
    {
        title: 'Bronzare Solar',
        items: [
            { title: 'Bronzare - 1 sedinta 15 minute', price: '30 lei' },
            { title: 'Abonament Bronzare - 10 sedinte fiecare de 15 minute', price: '250 lei' },
        ]
    },
    {
        title: 'Cosmetica si Terapii Faciale',
        items: [
            { title: 'Tratament Facial - Curatare si hidratare', price: '80 lei' },
            { title: 'Tratament Facial - Curatere - Hidratare - Oxigenare faciala - DrenajLimfatic - Tratament cu Radiofrecvente', price: '120 lei' },
            { title: 'Tratament Facial + Gat + Decolteu - Curatere - Ultrasunet - Oxigenare faciala - DrenajLimfatic - Tratament cu Radiofrecvente', price: '150 lei' },
            { title: 'Abonament 5x Tratament Facial - Curatere - Ultrasunet - Oxigenare faciala - DrenajLimfatic - Tratament cu Radiofrecvente', price: '500 lei' },
            { title: 'Abonament 5x Tratament Facial + Gat + Decolteu - Curatere - Ultrasunet - Oxigenare faciala - DrenajLimfatic - Tratament cu Radiofrecvente', price: '680 lei' },
            { title: 'HIFU Facial Nechiurgical - lifting facial 1.5h', price: '500 lei' },
            { title: 'HIFU Facial + Gat + Decolteu - lifting facial + gat + decolteu 2h', price: '800 lei' },
            { title: 'HIFU Gusa', price: '170 lei' },
            { title: 'HIFU Decolteu', price: '180 lei' },
        ]
    },
    {
        title: 'Presoterapie',
        items: [
            { title: 'Presoterapie - 1 sedință', price: '70 lei' },
            { title: 'Abonament Presoterapie - 10 sedințe', price: '500 lei' },
        ]
    },
    {
        title: 'Tunel cu infrarosu',
        items: [
            { title: 'Tunel 1 sedință', price: '45 lei' },
            { title: 'Abonament Tunel 4 sedinte', price: '160 lei' },
            { title: 'Abonament Tunel 8 sedinte', price: '300 lei' },
            { title: 'Abonament Tunel 10 sedinte', price: '350 lei' },
        ]
    },
    {
        title: 'Pachete promoționale Epilare definitivă',
        items: [
            { title: 'Pachet Full Body', price: '500 lei' },
            { title: 'Pachet 1 (Picioare lung, Axile, Linie Bikini)', price: '270 lei' },
            { title: 'Pachet 2 (Picioare lung, Inghinal, Linie Bikini)', price: '300 lei' },
            { title: 'Pachet 3 (Inghinal, Perianal, Linie Bikini, Mustata)', price: '140 lei' },
        ]
    },
    {
        title: 'Epilare definitivă',
        items: [
            { title: 'Epilare definitivă Mustață', price: '45 lei' },
            { title: 'Epilare definitivă Perciuni', price: '45 lei' },
            { title: 'Epilare definitivă Barbie', price: '45 lei' },
            { title: 'Epilare definitivă Frunte', price: '45 lei' },
            { title: 'Epilare definitivă Gât', price: '90 lei' },
            { title: 'Epilare definitivă Ceafă', price: '40 lei' },
            { title: 'Epilare definitivă Axilă', price: '90 lei' },
            { title: 'Epilare definitivă Inghinal', price: '130 lei' },
            { title: 'Epilare definitivă Perianal', price: '60 lei' },
            { title: 'Epilare definitivă Abdomen inferior', price: '100 lei' },
            { title: 'Epilare definitivă Abdomen total', price: '150 lei' },
            { title: 'Epilare definitivă linie bikini', price: '85 lei' },
            { title: 'Epilare definitivă Piept', price: '100 lei' },
            { title: 'Epilare definitivă Umeri', price: '100 lei' },
            { title: 'Epilare definitivă Brațe integral', price: '200 lei' },
            { title: 'Epilare definitivă Antebrat', price: '150 lei' },
            { title: 'Epilare definitivă Picioare lung', price: '290 lei' },
            { title: 'Epilare definitivă Coapse', price: '200 lei' },
            { title: 'Epilare definitivă Fese', price: '100 lei' },
            { title: 'Epilare definitivă Lombar', price: '100 lei' },
            { title: 'Epilare definitivă Spate integral', price: '250 lei' },
            { title: 'Epilare definitivă Față integral', price: '150 lei' },
            { title: 'Epilare definitivă Linie alba', price: '50 lei' },
        ]
    },
];