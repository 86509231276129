import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

const TermsAndConditions = () => {
    document.body.classList.add('page');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Termene si conditii | Novo Glam - Beauty Salon</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Descoperă salonul nostru de înfrumusețare, oferind servicii premium de epilare definitivă, lifting facial, tratamente faciale, presoterapie și bronzare la solar. Redefinește-ți frumusețea și relaxează-te în mâinile profesioniștilor noștri." />
                <meta name="keywords" content="salon de înfrumusețare, epilare definitivă, lifting facial, tratamente faciale, presoterapie, bronzare solar, îngrijire personală, piele netedă, rejuvenare facială" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <div id="page-title">
                    <div className="wrapper text-center">
                        <h1 className="large">Termeni și Condiții</h1>
                    </div>
                </div>

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <h2>Termeni și condiții de utilizare</h2>
                                <h3>Introducere</h3>
                                <p>Acești termeni și condiții se aplică între dvs., utilizatorul acestui site web (inclusiv orice subdomenii, cu excepția cazului în care acestea sunt excluse în mod expres de termenii și condițiile proprii) și Novo Glam, proprietarul și operatorul acestui site web. Citiți cu atenție acești termeni și condiții, deoarece acestea vă afectează drepturile legale. Acordul dvs. de a respecta și de a respecta acești termeni și condiții va apărea la prima utilizare a site-ului. Dacă nu sunteți de acord să respectați acești termeni și condiții, trebuie să opriți imediat utilizarea site-ului. În acești termeni și condiții, utilizatorul sau utilizatorii înseamnă orice terță parte care accesează site-ul și nu este (i) angajat de către Novo Glam sau care acționează în timpul angajării lor sau (ii) angajat în calitate de consultant sau furnizând în alt mod servicii Novo Glam și accesarea site-ului în legătură cu furnizarea acestor servicii. Trebuie să aveți cel puțin 18 ani pentru a utiliza acest site. Prin utilizarea site-ului și a acordului față de acești termeni și condiții, reprezentați și garantați că aveți cel puțin 18 ani.</p>

                                <h3>Proprietatea intelectuală și utilizarea acceptabilă</h3>
                                <p>1. Întregul conținut inclus pe site, cu excepția cazului în care este încărcat de utilizatori, este proprietatea lui Novo Glam, afiliații noștri sau alte părți terțe relevante. În acești termeni și condiții, Conținut înseamnă orice text, grafică, imagini, audio, video, software, compilații de date, aspectul paginii, codul și software-ul subiacent și orice altă formă de informații care poate fi stocată într-un computer care apare sau face parte a acestui site, inclusiv orice conținut încărcat de utilizatori. Continuând să utilizați site-ul web, recunoașteți că un astfel de conținut este protejat de drepturi de autor, de mărci comerciale, drepturi de bază de date și de alte drepturi de proprietate intelectuală. Nimic pe acest site nu va fi interpretat ca acordând, implicit, estoppel sau altfel orice licență sau drept de utilizare a oricărei mărci, sigle sau mărci de servicii afișate pe site fără permisiunea scrisă prealabilă a proprietarului.</p>
                                <p>2. Puteți utiliza numai pentru uz personal, non-comercial, următoarele: 2.1. extrageți, afișați și vizualizați conținutul pe un ecran de computer.</p>
                                <p>3. Nu trebuie să reproduceți, să modificați, să copiați, să distribuiți sau să utilizați în scopuri comerciale orice conținut fără permisiunea scrisă a acestuia Novo Glam.</p>

                                <h3>Utilizarea interzisă</h3>
                                <p>1. Nu aveți dreptul să utilizați site-ul web pentru oricare dintre următoarele scopuri: 1.1. în orice mod care cauzează sau poate cauza pagube site-ului sau interferează cu utilizarea sau vizualizarea de către alte persoane a Website-ului; 1.2. în orice mod care este dăunător, ilegal, abuziv, hărțuitor, amenințător sau în alt mod neconform sau care încalcă orice lege, reglementare, ordine guvernamentală aplicabilă; 1.3. producerea, transmiterea sau stocarea copiilor electronice ale conținutului protejat prin drepturi de autor fără permisiunea proprietarului.</p>

                                <h3>Înregistrare</h3>
                                <p>1. Trebuie să vă asigurați că detaliile furnizate de dvs. la înregistrare sau în orice moment sunt corecte și complete.</p>
                                <p>2. Trebuie să ne informați imediat despre orice modificare a informațiilor pe care le furnizați la înregistrare, prin actualizarea detaliilor personale, pentru a ne asigura că putem comunica eficient cu dvs.</p>
                                <p>3. Este posibil să suspendăm sau să anulam înregistrarea dvs. cu efect imediat din motive rezonabile sau dacă încălcați acești termeni și condiții. </p>
                                <p>4. Puteți să vă anulați înregistrarea în orice moment informându-ne în scris la adresa de la sfârșitul acestor termeni și condiții. Dacă faceți acest lucru, trebuie să vă opriți imediat utilizarea site-ului. Anularea sau suspendarea înregistrării dvs. nu afectează niciun drept legal.</p>

                                <h3>Legături către alte site-uri web</h3>
                                <p>1. Acest site poate conține legături către alte site-uri. Cu excepția cazului în care se prevede în mod expres, aceste site-uri nu sunt sub controlul Novo Glam sau cea a afiliaților noștri.</p>
                                <p>2. Nu ne asumăm nicio responsabilitate pentru conținutul acestor site-uri și nu ne asumăm răspunderea pentru niciun fel și pentru toate formele de pierdere sau daune rezultate din utilizarea lor.</p>
                                <p>3. Includerea unui link către un alt site de pe acest site nu implică nici o susținere a site-urilor sau a celor care le controlează.</p>

                                <h3>Politica de confidențialitate și politica de cookie-uri</h3>
                                <p>1. Utilizarea site-ului este, de asemenea, guvernată de politica noastră de confidențialitate și politica de cookie-uri, care sunt încorporate în acești termeni și condiții prin această referință. Pentru a vedea Politica de confidențialitate și Politica de cookie-uri, faceți clic pe următoarele: <a href={process.env.PUBLIC_URL + "/politica-de-confidentialitate"}>https://www.novo-glam.ro/politica-de-confidentialitate/</a>.</p>

                                <h3>Disponibilitatea site-ului și excluderea răspunderii</h3>
                                <p>1. Orice facilitate, instrument, serviciu sau informație online Novo Glam prin intermediul Website-ului (Serviciul) este furnizat „ca atare” și pe baza „ca fiind disponibil”. Nu oferim nicio garanție că serviciul nu va conține și / sau defecte. În măsura maximă permisă de lege, nu furnizăm garanții (exprese sau implicite) de adecvare pentru un anumit scop, exactitate de informații, compatibilitate și calitate satisfăcătoare. Novo Glam nu are obligația de a actualiza informațiile pe site.</p>
                                <p>2. Atata timp cat Novo Glam face eforturi rezonabile pentru a asigura că site-ul Web este securizat și fără erori, viruși și alte programe malware, nu acordăm nici o garanție sau garanție în acest sens și toți utilizatorii își asumă responsabilitatea pentru propria lor securitate, a datelor personale și a computerelor acestora.</p>
                                <p>3. Novo Glam nu își asumă nicio răspundere pentru orice întrerupere sau nedisponibilitate a site-ului.</p>
                                <p>4. Novo Glam își rezervă dreptul de a modifica, suspenda sau întrerupe orice parte (sau întregul) a Site-ului, incluzând, dar fără a se limita la, orice produse și / sau servicii disponibile. Acești termeni și condiții vor continua să se aplice oricărei versiuni modificate a site-ului web, cu excepția cazului în care se prevede altfel în mod expres.</p>

                                <h3>Limitare a răspunderii</h3>
                                <p>1. Nimic din acești termeni și condiții nu va: (a) limita sau exclude răspunderea noastră sau a dvs. pentru moartea sau vătămarea corporală care rezultă din neglijența noastră sau din cauza dvs., după caz; (b) limita sau exclude răspunderea noastră sau a dvs. pentru fraudă sau denaturare frauduloasă; sau (c) limitați sau excludeți vreunul din datoriile noastre sau din vreun fel care nu sunt permise în conformitate cu legislația în vigoare. </p>
                                <p>2. Nu vom fi răspunzători față de dvs. în ceea ce privește orice pierdere apăruta din evenimente care depășesc controlul nostru rezonabil. </p>
                                <p>3. În măsura maximă permisă de lege, Novo Glam nu își asumă răspunderea pentru niciuna dintre următoarele: 3.1. orice pierdere de afaceri, cum ar fi pierderea profitului, veniturile, economiile anticipate, afacerile, contractele, fondul comercial sau oportunitățile comerciale; 3.2. pierderea sau corupția oricăror date, baze de date sau software; 3.3. orice pierdere sau deteriorare specială, indirectă sau consecventă.</p>

                                <h3>General</h3>
                                <p>1. Nu puteți transfera oricare dintre drepturile dvs. în conformitate cu acești termeni și condiții unei alte persoane. Ne putem transfera drepturile în condițiile în care credem în mod rezonabil că drepturile dvs. nu vor fi afectate. </p>
                                <p>2. Acești termeni și condiții pot fi diferiți din când în când. Astfel de termeni revizuiți se vor aplica site-ului web de la data publicării. Utilizatorii ar trebui să verifice periodic termenii și condițiile pentru a se familiariza cu versiunea actuală.</p>
                                <p>3. Acești termeni și condiții, împreună cu politica de confidențialitate și politica privind cookie-urile, conțin întregul acord între părți referitor la obiectul său și înlocuiesc toate discuțiile, înțelegerile sau acordurile anterioare care ar fi putut avea loc în legătură cu termenii și condițiile. </p>
                                <p>4. Legea privind contractele (drepturile terților) din 1999 nu se aplică acestor termeni și condiții și nici o terță parte nu va avea nici un drept să aplice sau să se bazeze pe orice prevedere a acestor termeni și condiții.</p>
                                <p>5. Dacă o instanță sau o autoritate competentă constată că orice dispoziție a acestor termeni și condiții (sau o parte a oricăror prevederi) este nevalidă, ilegală sau inaplicabilă, această prevedere sau o parte din dispoziție va fi considerată, în măsura în care este necesar, eliminată și valabilitatea și aplicabilitatea celorlalte prevederi ale acestor termeni și condiții nu vor fi afectate. </p>
                                <p>6. Cu excepția cazului în care sa convenit altfel, nici o întârziere, acțiune sau omisiune a unei părți în exercitarea oricărui drept sau a unei căi de atac nu va fi considerată o renunțare la aceasta sau la orice alt drept sau remediere.</p>
                                <p>7. Prezentul acord este guvernat de și interpretat în conformitate cu legislația României, iar toate litigiile care decurg din acest acord (inclusiv litigiile sau creanțele necontractuale) sunt supuse jurisdicției exclusive a instanțelor din România.</p>

                                <h3>Novo Glam detalii</h3>
                                <p>1. Novo Glam este o societate înregistrată în România sub numele SC Novo Glam SRL cu Cod de Identificare Fiscală 44639473 a cărei adresă înregistrată este strada Calea Unirii Nr 101, Craiova, Romania și operează site-ul Web <a href={process.env.PUBLIC_URL}>https://www.novo-glam.ro/</a>. Puteti contacta Novo Glam prin email <a href="mailto:vladucu.voican@gmail.com">vladucu.voican@gmail.com</a>.</p>

                                <p>Conținutul „Termeni și Condiții” se poate modifica fara notificare. Novo Glam nu se obliga sa informeze cu privire la acest aspect. Ultima modificare a avut loc in data de 16.10.2024.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default TermsAndConditions;