import React from 'react';
import scriptLoader from 'react-async-script-loader';
import GoogleMapsStyle from '../../data/google-maps/googleMapsStyle';

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.map = null;
    }

    componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
        if (isScriptLoaded && !this.props.isScriptLoaded) {
            var address_latlng = new window.google.maps.LatLng(44.307046, 23.79744);
            // load finished
            if (isScriptLoadSucceed) {
                this.map = new window.google.maps.Map(this.mapRef.current, {
                    center: address_latlng,
                    zoom: 18,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: GoogleMapsStyle
                });

                var icon = {
                    url: '../../assets/img/about/small_pin.png',
                    size: new window.google.maps.Size(57, 60)
                };

                this.map.setCenter(address_latlng);

                new window.google.maps.Marker({
                    position: address_latlng,
                    map: this.map,
                    icon: icon
                });

            } else this.props.onError();
        }
    }

    render() {
        return (
            <div id="google-maps" ref={this.mapRef}></div>
        );
    }
}

export default scriptLoader(['https://maps.googleapis.com/maps/api/js?key=AIzaSyAVA11qV4nU9MecTAR9_cIjXUQVRhg4VzA'])(Maps);
