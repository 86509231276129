import React, { Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';

import { priceListItems } from '../data/services-inside/priceListItems.js';

const ServicesInside = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');

    // State to track the currently expanded item; initially set to the first item.
    const [expandedItem, setExpandedItem] = useState(0);

    // Function to toggle the expanded item
    const handleToggle = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Detalii servicii | Novo Glam - Beauty Salon</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Descoperă salonul nostru de înfrumusețare, oferind servicii premium de epilare definitivă, lifting facial, tratamente faciale, presoterapie și bronzare la solar. Redefinește-ți frumusețea și relaxează-te în mâinile profesioniștilor noștri." />
                <meta name="keywords" content="salon de înfrumusețare, epilare definitivă, lifting facial, tratamente faciale, presoterapie, bronzare solar, îngrijire personală, piele netedă, rejuvenare facială" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/pictures/1600x700.jpg" alt="spa" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Bine ai venit la Novo Glam, locul unde frumusețea ta este pe primul loc!</p>
                                            <p>La noi, te poți bucura de o experiență unică de relaxare și răsfăț, într-un spațiu modern și elegant. Echipa noastră de profesioniști este pregătită să îți ofere cele mai bune servicii de înfrumusețare, adaptate nevoilor și dorințelor tale.</p>
                                        </div>
                                    </div>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Listă de prețuri</h2>
                                        </div>

                                        <div className="price-list">
                                            {priceListItems.map((group, index) => (
                                                <div key={index} className="price-list-item">
                                                    <div
                                                        className="price-list-item-title"
                                                        onClick={() => handleToggle(index)}
                                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <h3 style={{ marginRight: '8px' }}>{group.title} {expandedItem === index ? '' : '>'}</h3>
                                                    </div>
                                                    {/* Show items only if the current item is expanded */}
                                                    {expandedItem === index && (
                                                        <ul className="price-list-item-list-group list-group">
                                                            {group.items.map((item, idx) => (
                                                                <li key={idx} className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title">{item.title}</span>
                                                                    <span className="list-group-price">{item.price}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside;
