import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© 2024 Beauty Salon by <a className="secondary-regular" href={ process.env.PUBLIC_URL + "/contacts" }>Novo Glam</a>2024© - CUI: 44639473 - Reg. Com. J16/1899/2021 </p>
        </div>
    );
};

export default Copyright;
