import React, { Component } from 'react';
import Swiper from 'swiper';

class ServicesHome extends Component {
    componentDidMount() {
        new Swiper('.adv-slider-services .adv-swiper-container', {
            // ADV swiper
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-2-swiper-button-next',
                prevEl: '.adv-2-swiper-button-prev'
            },
            spaceBetween: 0,
            slidesPerView: 'auto'
        });
    }

    render() {
        return (
            <section id="services" className="block">
                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">
                            <a title="Tanning" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/detalii-servicii"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Bronzare</h3>


                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_1.jpg" alt="Bronzare" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Facia Lifting Treatment" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/detalii-servicii"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Lifting facial</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_5.jpg" alt="Lifting facial" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Face Treatment" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/detalii-servicii"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Tratamente faciale</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_6.jpg" alt="Tratamente faciale" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Hair Removal Treatment" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/detalii-servicii"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Epilare definitivă</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_7.jpg" alt="Epilare definitivă" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Pressotherapy" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/detalii-servicii"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Presoterapie</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>

                                    <span style={{ position: 'absolute', right: 0, bottom: '-90px' }}>from
                                        <span onClick={() => window.location.href = "https://www.freepik.com/"} > freepik</span>
                                    </span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_8.jpg" alt="Presoterapie" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            {/* <a title="Hair Treatments" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/detalii-servicii" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Tratamente pentru păr</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_2.jpg" alt="Tratamente pentru păr" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Hands & Feets" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/detalii-servicii" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Manichiură & Pedichiură</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_3.jpg" alt="Manichiură & Pedichiură" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/detalii-servicii" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head" style={outlinedTextStyle}>Tunsori pentru barbati</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">află mai multe</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/pictures/780x500_4.jpg" alt="Tunsori pentru barbati" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>  */}
                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg btn-link border-0 p-0 min-w-auto">Înapoi</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg btn-link border-0 p-0 min-w-auto">Următorul</span>
                            </span>
                        </div>
                    </div>
                </div >
            </section >
        );
    }
}

export default ServicesHome;

const outlinedTextStyle = {
    color: 'white',
    WebkitTextStroke: '1px gray', // Thinner and lighter stroke for readability
    textShadow: `
      1px 1px 2px black, 
     -1px 1px 2px black, 
      1px -1px 2px black, 
     -1px -1px 2px black
    ` // Subtle shadow effect for extra clarity
};