import React, { Component } from 'react';
import axios from 'axios';
import { priceListItems } from '../../data/services-inside/priceListItems.js';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                name: '',
                services1: '',
                services2: '',
                services3: '',
                services4: '',
                phone: '',
                email: '',
                message: '',
                date: ''
            },
            successMessage: 'Mesajul tau a fost trimis cu succes!',
            warningMessage: 'Completeaza corect formularul!',
            errorMessage: 'S-a produs o eroare, te rog incearca mai tarziu!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000
        };
    }

    submitForm = async e => {
        e.preventDefault();

        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        this.setState({ isSubmitting: true });
        this.setState({ responseMessage: this.state.successMessage });
        this.callAlert(this.state.responseMessage, 'success');
        //     axios.post('https://store.adveits.com/API/form.php', this.state.values, {
        //         headers: {
        //             'Access-Control-Allow-Origin': '*',
        //             'Content-Type': 'application/json; charset=UTF-8'
        //         },
        //     },).then(response => {
        //         if (response.data.status === 'success') {
        //             this.setState({ responseMessage: this.state.successMessage });
        //         }

        //         if (response.data.status === 'warning') {
        //             this.setState({ responseMessage: this.state.warningMessage });
        //         }

        //         if (response.data.status === 'error') {
        //             this.setState({ responseMessage: this.state.successMessage });
        //         }

        //         this.callAlert(this.state.responseMessage, response.data.status)
        //     }).catch(error => {
        //         this.callAlert(this.state.errorMessage, 'error')
        //     });

    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);
        this.setState({
            alertTimeout: setTimeout(function () {
                var element = document.querySelector('#alert');
                element.classList.remove('fadeIn');
                element.classList.add('fadeOut');
                setTimeout(function () {
                    element.remove()
                }, 900)
            }, this.state.delay
            )
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 'success') {
                this.setState({ alertClass: 'success' })
            }

            if (type === 'error') {
                this.setState({ alertClass: 'danger' })
            }

            if (type === 'warning') {
                this.setState({ alertClass: 'warning' })
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });

    render() {
        return (
            <form method="post" onSubmit={this.submitForm} id="cf-1" className="contact-form">
                <div className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-name-surename">Nume si Prenume *</label>

                            <input name="name"
                                value={this.state.values.name}
                                onChange={this.handleInputChange}
                                type="text"
                                id="cf-1-name-surename"
                                placeholder="Nume si Prenume"
                                required="required" />
                        </span>

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-phone">Numarul tau de telefon *</label>

                            <input name="phone"
                                value={this.state.values.phone}
                                onChange={this.handleInputChange}
                                type="text"
                                id="cf-1-phone"
                                placeholder="Nr de telefon"
                                required="required" />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">

                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-services">Alege primul serviciu *</label>

                            <select name="services1"
                                id="cf-1-services"
                                value={this.state.values.services1}
                                onChange={this.handleInputChange}
                                required>
                                {priceListItems.map((category, index) => (
                                    category.items.map((item, idx) => (
                                        <option key={idx} value={item.title}>{item.title}</option>
                                    ))
                                ))}

                            </select>

                        </span>
                        <span className="gutter-width">

                            <label className="form-label-lg before after mb-0" htmlFor="cf-2-services">Alege al doilea serviciu </label>

                            <select name="services2"
                                id="cf-2-services"
                                value={this.state.values.services2}
                                onChange={this.handleInputChange}>
                                {priceListItems.map((category, index) => (
                                    category.items.map((item, idx) => (
                                        <option key={idx} value={item.title}>{item.title}</option>
                                    ))
                                ))}
                            </select>

                        </span>
                    </p>
                </div>

                <div className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">

                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-services">Alege al treilea serviciu </label>

                            <select name="services3"
                                id="cf-3-services"
                                value={this.state.values.services3}
                                onChange={this.handleInputChange}>
                                {priceListItems.map((category, index) => (
                                    category.items.map((item, idx) => (
                                        <option key={idx} value={item.title}>{item.title}</option>
                                    ))
                                ))}
                            </select>

                        </span>
                        <span className="gutter-width">

                            <label className="form-label-lg before after mb-0" htmlFor="cf-4-services">Alege al patrelea serviciu </label>

                            <select name="services4"
                                id="cf-4-services"
                                value={this.state.values.services4}
                                onChange={this.handleInputChange}>
                                {priceListItems.map((category, index) => (
                                    category.items.map((item, idx) => (
                                        <option key={idx} value={item.title}>{item.title}</option>
                                    ))
                                ))}
                            </select>

                        </span>
                    </p>
                </div>
                <div className="form-group form-group-lg">
                    <label className="form-label-lg before after mb-0" htmlFor="cf-1-message">Detalii</label>

                    <textarea name="message"
                        value={this.state.values.message}
                        onChange={this.handleInputChange}
                        id="cf-1-message"
                        placeholder="detaliaza mesajul tau aici"></textarea>
                </div>

                <div className="form-group form-group-lg mb-0">
                    <button type="submit" className="btn btn-outline-secondary">Trimite</button>
                </div>
            </form>
        );
    }
}

export default ContactForm;
