import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>Bine ai venit la Novo Glam, locul unde frumusețea ta este pe primul loc!</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>La noi, te poți bucura de o experiență unică de relaxare și răsfăț, într-un spațiu modern și elegant. Echipa noastră de profesioniști este pregătită să îți ofere cele mai bune servicii de înfrumusețare, adaptate nevoilor și dorințelor tale.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
