import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import SearchResults from './pages/SearchResults';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Services from './pages/Services';
import ServicesInside from './pages/ServicesInside';
import Page404 from './pages/404';
import UserInterface from './pages/UserInterface';

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/"}`}
                    element={<Home />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/despre-noi"}`}
                    element={<About />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/contacts"}`}
                    element={<Contacts />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/termene-si-conditii"}`}
                    element={<TermsAndConditions />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/politica-de-confidentialitate"}`}
                    element={<PrivacyPolicy />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/search-results"}`}
                    element={<SearchResults />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/servicii"}`}
                    element={<Services />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/ui"}`}
                    element={<UserInterface />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/detalii-servicii"}`}
                    element={<ServicesInside />}
                />
                <Route exact element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default App;
